<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text"><router-link to="/">Dashboard</router-link> / <router-link to="/integrations">Integrations</router-link> / Add Integration</h1>
      </v-flex>
      <v-flex xs12 md6>
        <p class="primary--text">Integration Channel</p>
        <v-text-field single-line outlined label="Integration" v-model="channel" ></v-text-field>
        <v-btn @click="addIntegration" class="mt-5 primary black--text">Submit</v-btn>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          API Secret
        </v-card-title>
        <v-card-text>
          <p class="mt-5">
            Please save or download the API secret key for your integration. You
            will not be able to view the token again.
          </p>
          <div
            class="pa-3"
            style="
              border: 1px solid #424242;
              border-radius: 5px;
              margin-top: 10px;
            "
          >
            <p class="accent--text">{{ token }}</p>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="copy"> Copy </v-btn>
          <v-btn color="primary" text> Download </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { BASE_URL } from '../../config'
import Axios from 'axios'
export default {
    data(){
        return{
            channel : "",
            dialog : false,
            integration : {},
            token : "",
        }
    },
    methods : {
        async addIntegration(){
          this.$store.dispatch("SET_PROGRESS",true)
            let url = BASE_URL + "/integration"
            let payload = {
                channel : this.channel
            }
            let {data} = await Axios.post(url,payload)
            this.token = data.token
            this.channel = ""
            this.$store.dispatch("SET_PROGRESS",false)
            this.dialog = true
            this.$toastr.s(
            
            "New integration created successfully.","Integration Added",
          );
        }
    }
}
</script>